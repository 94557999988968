import Vue from 'vue'
import Vuex from 'vuex'

import admin from './modules/admin'
import colors from './modules/colors'
import logo from './modules/logo'
import logo2 from './modules/logo2'
import main from './modules/main'
import profile from './modules/profile'
import usuarios from './modules/usuarios'
import suscripciones from './modules/suscripciones'
import banner from './modules/banner'
import category from  './modules/category'
import presentacion from './modules/type'
import product from './modules/product'
import product_val from './modules/product_val'
import extra from './modules/extra'
import subcategory from './modules/subcategory'
import atributo from './modules/atributo'
import stock from './modules/stock'
import mirror from './modules/mirror'
import shopcar  from './modules/shopcar'
import carrito from './modules/carrito'
import carrito2 from './modules/carrito2'
import pasarela from './modules/pasarela'
import wishlist from './modules/wishlist'
import shipment from './modules/shipment'
import payment from './modules/payment'
import global_params from './modules/global_params'
import address from './modules/address'
import store from './modules/store'
import catstore from './modules/catstore'
import copy from './modules/copy'
import estadisticas from './modules/estadisticas'
import gastos from './modules/gastos'
import ventas from './modules/ventas'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    admin: admin,
    main: main,
    usuarios: usuarios,
    profile: profile,
    colors: colors,
    logo: logo,
    logo2: logo2,
    suscripciones: suscripciones,
    banner: banner, 
    category: category,
    type: presentacion,
    product: product,
    product_val: product_val,
    mirror: mirror,
    extra: extra,
    subcategory: subcategory,
    stock: stock,
    atributo: atributo,
    carrito:carrito,
    carrito2:carrito2,
    shopcar: shopcar,
    pasarela: pasarela,
    payment: payment,
    wishlist: wishlist,
    shipment: shipment,
    global_params: global_params,
    address:address,
    store: store,
    catstore: catstore,
    copy:copy,
    estadisticas, 
    gastos,
    ventas,
  }
})
